import "core-js/modules/es.error.cause.js";
import { useI18n } from 'vue-i18n';
import { ref, nextTick, watch } from 'vue';
import { validateRequired, alertMessage, updateTextFormat, blurAllInput } from '@/assets/js/tool.js';
import { ElLoading } from 'element-plus';
import { createAuthCode } from '@/service/index';
import BasicDialog from '../Basic/BasicDialog.vue';
export default {
  props: {
    //v-model
    modelValue: Boolean
  },
  components: {
    BasicDialog
  },
  emits: ['update:modelValue', 'success'],
  setup(prop, {
    emit
  }) {
    //data
    const {
      t
    } = useI18n();
    const ruleForm = ref({
      mode: false,
      mac: '',
      date: ''
    });

    /**验证规则*/
    const rules = ref({
      mac: [{
        required: true,
        validator: (rule, value, callback) => {
          if (value === '') {
            callback(new Error(t('rules.MAC')));
          } else {
            let isMac = validateMac(value);
            if (!isMac) {
              callback(new Error(t('rules.MACFormat')));
            } else {
              callback();
            }
          }
        },
        trigger: 'blur'
      }],
      date: [{
        required: true,
        validator: (rule, value, callback) => {
          if (ruleForm.value.mode !== true) {
            validateRequired(value, callback, t('rules.ExpirationDate'));
          } else {
            callback();
          }
        },
        trigger: 'change'
      }]
    });

    //ref
    const ruleFormRef = ref(null);

    //watch
    watch(() => prop.modelValue, async newVal => {
      if (newVal) {
        ruleForm.value = {
          mode: false,
          mac: '',
          date: ''
        };
        await nextTick();
        ruleFormRef.value.resetFields();
      }
    });
    watch(() => ruleForm.value.mode, async newVal => {
      if (newVal === true) ruleForm.value.date = '';
      await nextTick();
      ruleFormRef.value.clearValidate();
    });

    //禁用时间选择器输入
    async function handleOpened() {
      await nextTick();
      document.querySelector('.item-date').querySelector('.el-input__inner').readOnly = true;
    }

    //点击取消
    function handleCancel() {
      emit('update:modelValue', false);
    }

    //点击确认
    function handleConfirm() {
      submit();
    }

    //提交
    async function submit(isBlur = false) {
      if (isBlur) blurAllInput('.create-authorization-form');
      await nextTick();
      ruleFormRef.value.validate(async valid => {
        if (valid) {
          let loading = ElLoading.service({
            lock: true,
            background: 'rgba(0, 0, 0, 0)'
          });
          let data = {
            forever: ruleForm.value.mode,
            mac: ruleForm.value.mac.toUpperCase()
          };
          if (data.forever === false) data.endDate = ruleForm.value.date + ' 23:59:59';
          let result = await createAuthCode(data);
          loading.close();
          if (result && result.status === 0) {
            alertMessage('success', t('message.OperationSucceeded'));
            emit('success');
          } else {
            let text = result ? result.msg || t('message.OperationFailed') : t('message.OperationFailed');
            alertMessage('error', text);
          }
        }
      });
    }

    //时间范围限制
    function disabledDate(time) {
      let now = Date.now();
      let arter = new Date(now + 1000 * 60 * 60 * 24 * 999);
      return time < now || time > new Date(arter);
    }

    //输入mac
    function handleInputMac() {
      let value = ruleForm.value.mac.replace(/[^0-9a-zA-Z]/g, '');
      value = value.replace(/:/g, '');
      value = updateTextFormat(value, 2, ':');
      value = value.replace(/::/g, ':');
      ruleForm.value.mac = value;
    }

    //粘贴mac
    function handlePasteMac(e) {
      e.preventDefault(); //阻止触发input事件
      let clip = e.clipboardData.getData('Text');
      if (!clip) return;
      clip = clip.trim();
      let isMac = validateMac(clip);
      if (isMac) {
        clip = clip.replace(/:/g, '');
      }
      let value = updateTextFormat(clip, 2, ':');
      ruleForm.value.mac = value;
    }

    //验证Mac
    function validateMac(mac) {
      mac = mac.trim();
      if (mac.indexOf(':') > -1) {
        let arr = mac.split(':');
        if (arr.length !== 6) return false;
        let isMac = true;
        for (let i = 0; i < arr.length; i++) {
          let bool = /^[A-Fa-f0-9]{2}$/.test(arr[i]);
          if (!bool) {
            isMac = false;
            break;
          }
        }
        return isMac;
      } else {
        return /^[A-Fa-f0-9]{10}$/.test(mac);
      }
    }

    //禁止ctrl+z回退
    function KeyDownZstop(e) {
      if (e.ctrlKey) e.preventDefault();
    }
    return {
      //data
      ruleForm,
      rules,
      //ref
      ruleFormRef,
      //function
      handleOpened,
      handleCancel,
      handleConfirm,
      submit,
      disabledDate,
      handleInputMac,
      handlePasteMac,
      KeyDownZstop
    };
  }
};