import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "authorization-list"
};
const _hoisted_2 = {
  class: "authorization-list-header"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "authorization-list-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ide_table = _resolveComponent("ide-table");
  const _component_create_authorization = _resolveComponent("create-authorization");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('message.Authorization')), 1), _createElementVNode("div", {
    class: "btn add",
    onClick: _cache[0] || (_cache[0] = $event => $setup.visible = true)
  }, _toDisplayString(_ctx.$t('message.NewCode')), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ide_table, {
    column: $setup.column,
    "row-height": 100,
    list: $setup.list,
    noMore: $setup.noMore,
    onOperation: $setup.handleClickCopy,
    onLoadMore: $setup.handleLoadMore,
    ref: "ideTableRef"
  }, null, 8, ["column", "list", "noMore", "onOperation", "onLoadMore"])]), _createVNode(_component_create_authorization, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.visible = $event),
    onSuccess: $setup.handleAddSuccess
  }, null, 8, ["modelValue", "onSuccess"])]);
}